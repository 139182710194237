:root {
	--pi-labs: rgb(52, 86, 93);
	--pi-labs-blue: rgb(0, 186, 255);
	--pi-labs-dark-blue: #003855;
	--pi-labs-shadow: rgba(52, 86, 93, 0.5);
	--pi-labs-grey: rgb(104, 137, 142);
	--pi-labs-grey-shadow: rgba(104, 137, 142, 0.5);
	--pixida: rgb(180, 10, 25);
	--pixida-shadow: rgba(180, 10, 25, 0.5);
	--font-family-sans-serif: Verdana !important;
	--trip-text-gray: #707070;
	--trip-border-gray: #90979b;
	--trip-background-gray: #dddddd;
	--trip-list-header-background: #61696e;
	--pilabs-blue: #00baff;
	--background-color: green;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

html,
body {
	height: 100vh;
	width: 100vw;
	margin: 0;
	padding: 0;
}

html,
.container {
	background-color: #f2f4f5;
}

body {
	line-height: 1.5rem;
	background-size: 40%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root,
.container {
	height: 100vh;
	width: 100vw;
}

code {
	font-family: 'NotoSansMono', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Remove auto-fill background-color */
input:-webkit-autofill {
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: inherit !important;
}

[background-color]:hover {
	background: var(--background-color);
}

::-webkit-scrollbar {
	overflow-y: scroll;
	width: 15px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: #009fef;
	border-radius: 10px;
	padding: 0 4px;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	background-clip: padding-box;
}
